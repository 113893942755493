import {NODE_ENV, STATIC_MODE} from 'config/index';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import {getLocalePrefix} from 'hooks/useLocalePrefix';
import Image from 'next/image';
import {useRouter} from 'next/router';
import React, {FC} from 'react';

import Masonry from '@mui/lab/Masonry';
import {useMediaQuery} from '@mui/material';

import {TypoComponentsNewsGrid, TypoComponentsNewsGridFeaturedNewsDataItem} from 'lib/api/strapi';

import {generateWrapperConfig} from 'utils/strapi-components/getComponent';

import {palette} from 'styles/mui/scss';

import {Box, Button, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import {NextLink} from 'components/basic-components/NextLink/Link';
import {withErrorBoundary} from 'components/error/ErrorBoundary';
import {DiagonalWrapper} from 'components/layout-components/DiagonalWrapper/DiagonalWrapper';

import {filterUniqueArray} from '../../../utils/dataModeling/filterUniqueArray';
import styles from './NewsGrid.module.scss';
import { getLinkForNews } from 'utils/dataFetching/news/getLinkForNews';

const NewsGrid: FC<TypoComponentsNewsGrid> = ({
  title,
  titleLevel,
  backgroundImage,
  featuredNews,
  latest_news,
}) => {
  const displayedNews:TypoComponentsNewsGridFeaturedNewsDataItem[] = filterUniqueArray([
    ...(featuredNews?.data.filter(
      newsItem => !(STATIC_MODE && NODE_ENV === 'production' && !newsItem?.attributes?.publishedAt),
    ) || []),
    ...latest_news?.data.filter(
      newsItem => !(STATIC_MODE && NODE_ENV === 'production' && !newsItem?.attributes?.publishedAt),
    ),
  ]).slice(0, 6);
  const {locale, defaultLocale} = useRouter();
  const render4News = useMediaQuery('(min-width:630px)');
  const render6News = useMediaQuery('(min-width:1000px)');
  const {t} = useTranslation('strapiComponents');
  let displayedNewsToRender: TypoComponentsNewsGridFeaturedNewsDataItem[];

  if (render6News) {
    displayedNewsToRender = displayedNews.slice(0, 6); // Render 4 news articles
  } else if (render4News) {
    displayedNewsToRender = displayedNews.slice(0, 4); // Render 6 news articles
  } else {
    displayedNewsToRender = displayedNews.slice(0, 3); // Render 3 news articles when viewport smaller than 630px
  }

  return (
    <DiagonalWrapper
      config={{...generateWrapperConfig(), diagonal: {top: true, bottom: true}}}
      diagonalBackgroundImage
      backgroundImage={
        backgroundImage?.data?.attributes?.url
          ? `url(${backgroundImage?.data?.attributes?.url})`
          : palette.colorDarkGrey
      }
    >
      <Box className={styles.wrapper}>
        <Box>
          <Typography
            component={titleLevel}
            className={styles.title}
            sx={{
              typography: {xxs: 'titleMobile', sm: 'h1Small'},
            }}
          >
            {title}
          </Typography>
          <Masonry
            columns={{xxs: 1, sm: 2, xl: 3}}
            spacing={0}
            sx={{marginRight: '-1%', marginLeft: '-1%'}}
          >
            {displayedNewsToRender.map(newsArticle => {
              const hrefValue = getLinkForNews(newsArticle)
              return (
                <Box className={styles.newsWrapper} key={newsArticle.id}>
                  <NextLink href={hrefValue}>
                    <Box sx={{position: 'relative'}}>
                      <Box className={styles.background} />
                      <Image
                        width={newsArticle?.attributes?.preview_image?.data?.attributes?.width}
                        height={newsArticle?.attributes?.preview_image?.data?.attributes?.height}
                        className={styles.image}
                        src={newsArticle?.attributes?.preview_image?.data?.attributes?.url}
                        alt=""
                      />
                      <Typography variant="teaser" className={styles.newsArticleTitle}>
                        {newsArticle?.attributes?.title}
                      </Typography>
                    </Box>
                  </NextLink>
                  <Box className={styles.newsTextWrapper}>
                    <NextLink className={styles.link} href={hrefValue}>
                      <Typography className={styles.newsArticleDescription}>
                        {newsArticle?.attributes?.description}
                      </Typography>
                      <Box className={styles.ctaWrapper}>
                        <Typography variant="buttonCards" className={styles.ctaText}>
                          {newsArticle?.attributes?.cta_text}
                        </Typography>
                        <CustomIcon name="arrow" className={styles.icon} />
                      </Box>
                    </NextLink>
                  </Box>
                </Box>
              );
            })}
          </Masonry>
          <Box className={styles.buttonWrapper}>
            <NextLink href={`${getLocalePrefix({locale, defaultLocale, slash: 'front'})}/news`}>
              <Button className={styles.button}>{t('toNewsPortal')}</Button>
            </NextLink>
          </Box>
        </Box>
      </Box>
    </DiagonalWrapper>
  );
};

export default withErrorBoundary(NewsGrid, ' NewsGrid');
