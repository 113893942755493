import { TypoComponentsNewsListNewsDataItem } from "lib/api/strapi";

export const getLinkForNews = (newsArticle: TypoComponentsNewsListNewsDataItem, noSlashPrefix = false) => {
    const categorySlug =
    newsArticle?.attributes?.news_category?.data?.attributes?.category_slug;
  const blogCategory =
    newsArticle?.attributes?.blog_category?.data?.attributes?.category_slug;
  const articleSlug = newsArticle?.attributes?.slug;

  let hrefValue = noSlashPrefix ? 'news/' : '/news/';
  if(categorySlug === 'blog') {
    hrefValue = noSlashPrefix ? 'blog/' : '/blog/';
    if (blogCategory && articleSlug) {
      hrefValue += `${blogCategory}/${articleSlug}`;
    } else if (articleSlug) {
      hrefValue += articleSlug;
    }
  } else {
    if (categorySlug && articleSlug) {
      hrefValue += `${categorySlug}/${articleSlug}`;
    } else if (articleSlug) {
      hrefValue += articleSlug;
    }
  }

  return hrefValue
}